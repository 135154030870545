<template>
  <validation-observer ref="rangesObserver">
    <v-row
      v-for="(range, index) in ranges"
      :key="index"
      align="baseline"
      class="mt-0 mb-0 pt-0 pb-0"
    >
      <v-col cols="12" md="4" class="mt-0 mb-0 pt-0 pb-0">
        <DatePicker
          class="required"
          :date="ranges[index].from"
          :is-required="true"
          :name="$t('PACKAGES.FORM.RANGE_CONTROL_FROM_DATE')"
          :input.sync="ranges[index].from"
          :change="date => clearToDate(index, date)"
          required
        />
      </v-col>
      <v-col cols="12" md="4" class="mt-0 mb-0 pt-0 pb-0">
        <DatePicker
          class="required"
          :date="ranges[index].to"
          :is-required="true"
          :name="$t('PACKAGES.FORM.RANGE_CONTROL_TO_DATE')"
          :input.sync="ranges[index].to"
          :date-min="ranges[index].from"
          required
        />
      </v-col>
      <v-col cols="12" md="3" class="mt-0 mb-0 pt-0 pb-0">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('PACKAGES.FORM.RANGE_CONTROL_AMOUNT')"
          rules="required|numeric"
        >
          <v-text-field
            class="required"
            v-model="ranges[index].amount"
            dense
            outlined
            :error-messages="errors"
            :label="$t('PACKAGES.FORM.RANGE_CONTROL_AMOUNT')"
            required
            hide-details="auto"
            :input.sync="ranges[index].amount"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col
        v-if="ranges.length > 1 && !isOnlyShow"
        cols="12"
        md="1"
        class="mb-2 "
      >
        <a
          href="#"
          class="btn btn-icon btn-light btn-hover-danger"
          @click="deleteRange(index, $event)"
        >
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <v-icon color="red">
              mdi-delete
            </v-icon>
          </span>
        </a>
      </v-col>
      <v-col cols="12" md="8" class="mt-0 pt-0 mb-0 pb-0">
        <h5>
          {{ differenceTwoDates(index) }}
        </h5>
      </v-col>
    </v-row>
    <v-btn
      depressed
      color="primary"
      @click="addRange"
      class="mt-0 mb-2"
      v-if="!isOnlyShow"
    >
      {{ $t("PACKAGES.FORM.RANGE_CONTROL_ADD") }}
    </v-btn>
  </validation-observer>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

import DatePicker from "@/view/content/components/DatePicker";

export default {
  name: "RangeControl",
  props: ["rangesData", "isOnlyShow"],
  components: {
    ValidationObserver,
    ValidationProvider,
    DatePicker,
  },
  data() {
    return {
      ranges: [
        {
          from: null,
          to: null,
          amount: null,
        },
      ],
    };
  },
  mounted() {
    if (this.rangesData.length) {
      this.ranges = this.rangesData;
    }
  },
  methods: {
    async addRange() {
      const validate = await this.$refs.rangesObserver.validate();
      if (validate) {
        this.ranges.push({
          from: null,
          to: null,
        });
      }
    },
    deleteRange(index, event) {
      event.preventDefault();
      this.ranges.splice(index, 1);
    },
    clearToDate(index, date) {
      if (this.ranges[index].to) {
        this.ranges[index].from = date;
        this.ranges[index].to = null;
      }
    },
    differenceTwoDates(index) {
      let result = "";
      if (this.ranges[index].from && this.ranges[index].to) {
        const startDate = this.$moment(this.ranges[index].from);
        const endDate = this.$moment(this.ranges[index].to);
        let difference = endDate.diff(startDate, "days");
        if (difference < 0) {
          difference = difference * 2;
        }
        if (difference <= 7) {
          result = `${this.$i18n.t(
            "PACKAGES.FORM.RANGE_CONTROL_DURATION_LABEL"
          )}: ${difference} ${this.$i18n.t(
            "PACKAGES.FORM.RANGE_CONTROL_DAYS_LABEL"
          )}`;
        } else {
          const quantityWeeks = parseInt(difference) / 7;
          const quantityWeeksRounded = Math.floor(quantityWeeks);
          const diffDays = difference - quantityWeeksRounded * 7;
          result = `${this.$i18n.t(
            "PACKAGES.FORM.RANGE_CONTROL_DURATION_LABEL"
          )}: ${quantityWeeksRounded} ${this.$i18n.t(
            "PACKAGES.FORM.RANGE_CONTROL_WEEKS_LABEL"
          )} and ${diffDays} ${this.$i18n.t(
            "PACKAGES.FORM.RANGE_CONTROL_DAYS_LABEL"
          )}`;
        }
      }
      return result;
    },
  },
  watch: {
    ranges: {
      handler: function() {
        this.$emit("update:ranges", this.ranges);
      },
      deep: true,
    },
  },
};
</script>
