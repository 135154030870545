<template>
  <validation-observer ref="observer">
    <v-form :disabled="isOnlyShow">
      <v-card class="mb-2">
        <v-card-title>{{ $t("PACKAGES.FORM.INFO_TITLE") }}</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PACKAGES.FORM.NAME_FIELD')"
                rules="min:5|required"
              >
                <v-text-field
                  v-model="form.name"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PACKAGES.FORM.NAME_FIELD')"
                  required
                >
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PACKAGES.FORM.DESCRIPTION_FIELD')"
                rules="required"
              >
                <v-textarea
                  v-model="form.description"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PACKAGES.FORM.DESCRIPTION_FIELD')"
                  required
                >
                </v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-2">
        <v-card-title>
          <span>
            {{ $t("PACKAGES.FORM.PACKAGE_DURATION_TITLE") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row class="pb-0 mb-0" no-gutters>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PACKAGES.FORM.PACKAGE_DURATION_TITLE')"
                rules="required"
              >
                <v-select
                  v-model="form.packageDurationType"
                  dense
                  outlined
                  :error-messages="errors"
                  :label="$t('PACKAGES.FORM.PACKAGE_DURATION_TYPE_FIELD')"
                  required
                  :items="typesDuration"
                  item-value="id"
                  item-text="title"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <VariableControl
                v-if="form.packageDurationType === typesOfDuration('variable')"
                :weekly.sync="form.weekly"
                :weeklyData="weeklyData"
                :startDateTypeData="startDateTypeData"
                :startDateType.sync="form.startDateType"
                :startDateTemplateData="startDateTemplateData"
                :startDateTemplate.sync="form.startDateTemplate"
                :isOnlyShow="isOnlyShow"
              ></VariableControl>
              <FixedControl
                v-if="form.packageDurationType === typesOfDuration('fixed')"
                :ranges.sync="form.ranges"
                :rangesData="rangeData"
                :isOnlyShow="isOnlyShow"
              ></FixedControl>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-2">
        <v-card-title>
          <span class="required-label">
            {{ $t("PACKAGES.FORM.PACKAGE_OPTIONS_TITLE") }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row v-if="form.packageOptions.length">
            <v-col
              cols="12"
              class="m-0 p-1"
              v-for="(packageOption, index) in form.packageOptions"
              :key="index"
            >
              <PackageOptionsTable
                :index="index"
                :package-option="packageOption"
                :edit-option="openEditPackageOption"
                :delete-option="deletePackageOption"
                :isOnlyShow="isOnlyShow"
              ></PackageOptionsTable>
            </v-col>
          </v-row>
          <v-row class="mb-0" v-if="!form.packageOptions.length">
            <v-col cols="12">
              <v-btn
                depressed
                color="primary"
                @click="addPackageOption"
                class="mt-0 mb-2"
              >
                {{ $t("PACKAGES.FORM.PACKAGE_OPTION_ADD") }}
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="isRequired">
              <div class="v-messages theme--light error--text mt-1">
                {{ $t("PACKAGES.FORM.PACKAGE_OPTION_REQUIRED_MESSAGE") }}
              </div>
            </v-col>
          </v-row>
          <PackageOptionsModal
            v-if="!isOnlyShow"
            ref="packageOptionModal"
            :package-options-modal-data="packageOptionsModalData"
            :is-open="isOpen"
            :add-pricing="form.packageDurationType !== typesOfDuration('fixed')"
            :open-dialog="openDialog"
            :save-package-option="savePackageOption"
            :edit-package-option="editPackageOption"
          ></PackageOptionsModal>
        </v-card-text>
      </v-card>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

import VariableControl from "@/view/pages/packages/components/VariableControl";
import FixedControl from "@/view/pages/packages/components/FixedControl";
import PackageOptionsModal from "@/view/pages/packages/components/PackageOptionsModal";
import PackageOptionsTable from "@/view/pages/packages/components/PackageOptionsTable";

export default {
  name: "Form",
  props: {
    package: {
      type: Object,
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VariableControl,
    FixedControl,
    PackageOptionsModal,
    PackageOptionsTable,
  },
  data() {
    return {
      errors: [],
      isShowDialogDelete: false,
      isOpen: false,
      isRequired: false,
      packageOptionsModalData: null,
      typesDuration: [
        {
          id: 2,
          title: this.$t("PACKAGES.FORM.PACKAGE_DURATION_FIXED_OPTION"),
        },
        {
          id: 1,
          title: this.$t("PACKAGES.FORM.PACKAGE_DURATION_VARIABLE_OPTION"),
        },
        {
          id: 3,
          title: this.$t("PACKAGES.FORM.PACKAGE_DURATION_APPLY_OPTION"),
        },
      ],
      form: {
        name: null,
        description: null,
        packageDurationType: null,
        weekly: {},
        ranges: [],
        packageOptions: [],
        startDateTemplate: {},
        startDateType: 0,
      },
    };
  },
  computed: {
    startDateTypeData() {
      if (!this.package) return null;
      return this.package.startDateType;
    },
    startDateTemplateData() {
      if (!this.package) return null;
      return this.package.startDateTemplate;
    },
    weeklyData() {
      if (!this.package) return null;
      return this.package.weekly;
    },
    rangeData() {
      if (!this.package) return [];
      return this.package.ranges;
    },
  },
  methods: {
    async validateForm() {
      this.isRequired = !this.form.packageOptions.length;
      return await this.$refs.observer.validate();
    },
    addPackageOption() {
      this.packageOptionsModalData = null;
      this.openDialog(true);
    },
    savePackageOption(option) {
      this.form.packageOptions.push(option);
      this.isRequired = false;
    },
    editPackageOption(option) {
      this.form.packageOptions[this.openEditIndex] = option;
    },
    openEditPackageOption(index) {
      this.openEditIndex = index;
      this.packageOptionsModalData = this.form.packageOptions[index];
      this.openDialog(true);
    },
    deletePackageOption(index) {
      this.form.packageOptions.splice(index, 1);
    },
    openDialog(value) {
      this.isOpen = value;
    },
    typesOfDuration(type) {
      const types = {
        variable: 1,
        fixed: 2,
      };
      return types[type];
    },
  },
  watch: {
    form: {
      handler: function() {
        this.$emit("update:form-data", this.form);
      },
      deep: true,
    },
    package(newVal) {
      if (newVal) {
        this.form = newVal;
      }
    },
  },
};
</script>
