<template>
  <validation-observer ref="validateModal">
    <v-dialog
      :key="componentKey"
      v-model="isOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <v-card class="PackageOptionModal">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t("PACKAGES.FORM.MODAL.TITLE_ADD") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="addPackageOption">
              {{ $t("PACKAGES.FORM.MODAL.SAVE") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="12" class="m-0 p-0 mt-2">
              <v-list expand class="PackageOptionList">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="h4">
                      <span class="required-label">
                        {{ $t("PACKAGES.FORM.MODAL.COURSE_SECTION_TITLE") }}
                      </span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                        </template>
                        {{
                          $t("PACKAGES.FORM.MODAL.COURSE_SECTION_NEW_COURSE")
                        }}
                      </v-tooltip>
                    </v-list-item-title>
                    <v-row>
                      <v-col cols="12">
                        <ProductsTreeView
                          :name="$t('PACKAGES.FORM.MODAL.COURSE_SECTION_TITLE')"
                          :input.sync="
                            packageOption.packageOptionItems.coursesSelected
                          "
                          :data="courses"
                          :selection="
                            packageOption.packageOptionItems.coursesSelected
                          "
                          is-required="true"
                          :select-all="false"
                        />
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-list-group
                  :value="packageOption.packageOptionItems.roomsSelected.length"
                >
                  <template v-slot:activator>
                    <v-list-item-content class="m-0">
                      <v-list-item-title class="h4">
                        {{ $t("PACKAGES.FORM.MODAL.ROOM_SECTION_TITLE") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          {{ $t("PACKAGES.FORM.MODAL.ROOM_SECTION_NEW_ROOM") }}
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <ProductsTreeView
                            key-name="room_with_lodging"
                            :name="$t('PACKAGES.FORM.MODAL.ROOM_SECTION_TITLE')"
                            :input.sync="
                              packageOption.packageOptionItems.roomsSelected
                            "
                            :data="rooms"
                            :selection="
                              packageOption.packageOptionItems.roomsSelected
                            "
                            :select-all="false"
                          />
                        </v-col>
                        <v-col
                          v-if="
                            packageOption.packageOptionItems.roomsSelected
                              .length
                          "
                        >
                          <h5>
                            {{
                              $t(
                                "PACKAGES.FORM.MODAL.ROOM_SECTION_DURATION_TITLE"
                              )
                            }}
                          </h5>
                          <v-row class="mb-0">
                            <v-col cols="12">
                              <v-radio-group
                                v-model="durationOption"
                                dense
                                row
                                class="m-0"
                              >
                                <v-radio
                                  :value="1"
                                  :label="
                                    $t(
                                      'PACKAGES.FORM.MODAL.ROOM_RADIO_USE_DURATION'
                                    )
                                  "
                                ></v-radio>
                                <v-radio
                                  :value="2"
                                  :label="
                                    $t(
                                      'PACKAGES.FORM.MODAL.PRICING_RADIO_ENTER_CUSTOM'
                                    )
                                  "
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                          <v-row class="mb-0" v-if="durationOption === 2">
                            <v-col md="2" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="
                                  $t(
                                    'PACKAGES.FORM.MODAL.SERVICES_CONTROL_QUANTITY'
                                  )
                                "
                                rules="required|integer|min_value:1"
                              >
                                <v-text-field
                                  type="number"
                                  v-model="
                                    packageOption.packageOptionItems
                                      .durationAmount
                                  "
                                  dense
                                  outlined
                                  :error-messages="errors"
                                  :label="
                                    $t(
                                      'PACKAGES.FORM.MODAL.SERVICES_CONTROL_QUANTITY'
                                    )
                                  "
                                  required
                                >
                                </v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col md="3" sm="8">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="
                                  $t(
                                    'PACKAGES.FORM.WEEKLY_CONTROL_DURATION_TYPE_FIELD'
                                  )
                                "
                                rules="required"
                              >
                                <v-select
                                  v-model="
                                    packageOption.packageOptionItems
                                      .durationTypeId
                                  "
                                  :items="getDurationTypes"
                                  :label="
                                    $t(
                                      'PACKAGES.FORM.WEEKLY_CONTROL_DURATION_TYPE_FIELD'
                                    )
                                  "
                                  item-text="codeName"
                                  item-value="durationTypeId"
                                  dense
                                  outlined
                                  :error-messages="errors"
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group
                  :value="
                    packageOption.packageOptionItems.servicesSelected.length
                  "
                >
                  <template v-slot:activator>
                    <v-list-item-content class="m-0">
                      <v-list-item-title class="h4">
                        {{ $t("PACKAGES.FORM.MODAL.SERVICE_SECTION_TITLE") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          {{
                            $t(
                              "PACKAGES.FORM.MODAL.SERVICE_SECTION_NEW_SERVICE"
                            )
                          }}
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row class="mb-0">
                        <v-col>
                          <ServicesControl
                            :services-selected-data="
                              packageOption.packageOptionItems.servicesSelected
                            "
                            :services-data="addons"
                            :services.sync="
                              packageOption.packageOptionItems.servicesSelected
                            "
                          ></ServicesControl>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-group
                  :value="packageOption.packageOptionItems.feesSelected.length"
                >
                  <template v-slot:activator>
                    <v-list-item-content class="m-0">
                      <v-list-item-title class="h4">
                        {{ $t("PACKAGES.FORM.MODAL.FEE_SECTION_TITLE") }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                          </template>
                          {{ $t("PACKAGES.FORM.MODAL.FEE_SECTION_NEW_FEE") }}
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <ProductsTreeView
                            :name="$t('PACKAGES.FORM.MODAL.FEE_SECTION_TITLE')"
                            :input.sync="
                              packageOption.packageOptionItems.feesSelected
                            "
                            :selection="
                              packageOption.packageOptionItems.feesSelected
                            "
                            key-id="feeId"
                            :data="listAllFees"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <v-list-item v-if="addPricing">
                  <v-list-item-content>
                    <v-list-item-title class="h4">
                      <span class="required-label">
                        {{ $t("PACKAGES.FORM.MODAL.PRICING_SECTION_TITLE") }}
                      </span>
                    </v-list-item-title>
                    <v-row>
                      <v-col cols="12">
                        <v-radio-group
                          :key="componentKey"
                          class="m-0"
                          v-model="pricingOption"
                          dense
                          row
                        >
                          <v-radio
                            :value="1"
                            :label="
                              $t(
                                'PACKAGES.FORM.MODAL.PRICING_RADIO_USE_TEMPLATE'
                              )
                            "
                          ></v-radio>
                          <v-radio
                            :value="2"
                            :label="
                              $t(
                                'PACKAGES.FORM.MODAL.PRICING_RADIO_ENTER_TEMPLATE'
                              )
                            "
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" v-if="pricingOption === 2">
                        <PricingTemplate
                          :showMessages="false"
                          :productPricingTemplateId="productPricingTemplateId"
                          :availableOptions="[1, 2, 3]"
                          ref="pricingTemplate"
                          :key="componentKey"
                        ></PricingTemplate>
                      </v-col>
                      <v-col sm="12" md="5" v-else>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="
                            $t(
                              'PACKAGES.FORM.MODAL.PRICING_CONTROL_PRICE_TEMPLATE_LIST'
                            )
                          "
                          rules="required"
                        >
                          <v-autocomplete
                            v-model="packageOption.productPricingTemplateId"
                            :items="productPricings"
                            :label="
                              $t(
                                'PACKAGES.FORM.MODAL.PRICING_CONTROL_PRICE_TEMPLATE_LIST'
                              )
                            "
                            item-text="name"
                            item-value="productPricingTemplateId"
                            dense
                            outlined
                            :error-messages="errors"
                            hide-selected
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";

import ProductsTreeView from "@/view/content/components/ProductsTreeView";
import ServicesControl from "@/view/pages/packages/components/ServicesControl";
import durationTypesFormat, {
  DEFAULT_DURATION_TYPE,
} from "@/core/services/utils/durationTypesFormat";

const USE_DURATION = 1;

export default {
  name: "PackageOptionsModal",
  props: {
    packageOptionsModalData: null,
    isOpen: {
      type: Boolean,
      default: false,
    },
    addPricing: {
      type: Boolean,
      default: false,
    },
    openDialog: {
      type: Function,
      required: true,
    },
    savePackageOption: {
      type: Function,
      required: true,
    },
    editPackageOption: {
      type: Function,
      required: true,
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    PricingTemplate,
    ProductsTreeView,
    ServicesControl,
  },
  data() {
    return {
      pricingOption: 1,
      durationOption: 1,
      componentKey: 0,
      packageOption: {
        name: null,
        productPricingTemplateId: null,
        packageOptionItems: {
          coursesSelected: [],
          roomsSelected: [],
          servicesSelected: [],
          feesSelected: [],
          durationTypeId: DEFAULT_DURATION_TYPE,
          durationAmount: null,
        },
      },
      PRODUCT_TYPE_PACKAGE: 6,
    };
  },
  created() {
    if (this.packageOptionsModalData) {
      this.packageOption = this.packageOptionsModalData;
    }
  },
  mounted() {
    this.getCourses();
    this.getAddons();
    this.getRooms();
    this.getAllProductPricings();
    this.getListFees();
  },
  computed: {
    ...mapGetters({ fees: "getFees" }),
    ...mapGetters([
      "courses",
      "rooms",
      "addons",
      "productPricings",
      "DurationTypes",
      "productPricing",
    ]),
    getDurationTypes() {
      const codeNames = ["WEEK", "MONTH", "YEAR"];
      return durationTypesFormat(this.DurationTypes, codeNames);
    },
    productPricingTemplateId: function() {
      const isTemplate = this.productPricings.filter(
        pt =>
          pt.productPricingTemplateId ===
            this.packageOption.productPricingTemplateId && pt.isReady
      ).length;
      return this.packageOption.productPricingTemplateId !== null && !isTemplate
        ? this.packageOption.productPricingTemplateId
        : 0;
    },
    listAllFees() {
      return this.fees.filter(item => {
        item.name_with_price = `${item.name} (${item.price})`;
        return item.applicableTo === this.PRODUCT_TYPE_PACKAGE;
      });
    },
  },
  methods: {
    ...mapActions([
      "getCourses",
      "getAddons",
      "getRooms",
      "getAllProductPricings",
      "getListFees",
    ]),
    async addPackageOption() {
      const observer = this.$refs.validateModal;
      const validPricingTemplate =
        this.pricingOption === 2 && this.addPricing
          ? await this.$refs.pricingTemplate.validateAndGetData()
          : true;
      if ((await observer.validate()) && validPricingTemplate) {
        if (
          validPricingTemplate &&
          this.pricingOption === 2 &&
          this.addPricing
        ) {
          const { productPricingTemplateId } = validPricingTemplate;
          if (productPricingTemplateId) {
            await this.$refs.pricingTemplate.save(validPricingTemplate);
          } else {
            await this.$refs.pricingTemplate.store(validPricingTemplate);
            this.packageOption.productPricingTemplateId = this.productPricing.productPricingTemplateId;
          }
        }

        if (this.durationOption === USE_DURATION) {
          this.packageOption.packageOptionItems.durationAmount = null;
          this.packageOption.packageOptionItems.durationTypeId = DEFAULT_DURATION_TYPE;
        }

        if (this.packageOptionsModalData) {
          this.editPackageOption(this.packageOption);
        } else {
          this.savePackageOption(this.packageOption);
        }
        this.dataReset();
        this.openDialog(false);
      } else {
        this.$bvToast.toast(
          this.$t("GENERAL.PLEASE_VERIFY_FIELDS").toString(),
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
        for (const key of Object.keys(observer.fields).sort()) {
          if (observer.fields[key].invalid) {
            observer.refs[key]?.$el.scrollIntoView();
            return;
          }
        }
      }
    },
    closeModal() {
      this.dataReset();
      this.openDialog(false);
    },
    dataReset() {
      this.componentKey += 1;
      this.durationOption = 1;
      this.pricingOption = 1;
      this.packageOptionsModalData = null;
      this.packageOption = {
        name: null,
        productPricingTemplateId: null,
        packageOptionItems: {
          coursesSelected: [],
          roomsSelected: [],
          servicesSelected: [],
          feesSelected: [],
          durationTypeId: DEFAULT_DURATION_TYPE,
          durationAmount: null,
        },
      };
    },
  },
  watch: {
    packageOptionsModalData: {
      handler: function(newVal) {
        if (newVal) {
          this.packageOption = this.packageOptionsModalData;
          this.durationOption =
            this.packageOptionsModalData.packageOptionItems.durationAmount !==
            null
              ? 2
              : 1;
          const isTemplate = this.productPricings.filter(
            pt =>
              (pt.productPricingTemplateId ===
                this.packageOption.productPricingTemplateId) &
              pt.isReady
          ).length;
          this.pricingOption = isTemplate ? 1 : 2;
        } else {
          this.dataReset();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.PackageOptionModal {
  .v-card__text {
    height: 100vh;
  }
  .v-list-group {
    border-top: 1px solid #eeeeee;
    &:nth-child(4) {
      border-bottom: 1px solid #eeeeee;
    }
  }
  .v-list-group__items {
    background: #fcfcfc;
    border-top: 1px solid #f0f0f0;
  }
  .simple-tree {
    font-size: 16px;
    .v-treeview-node__children {
      min-height: 75px;
    }
  }
  .v-select.v-input--dense .v-select__selection--comma {
    white-space: pre-wrap;
  }
  .pricing-new .content-form {
    margin: 0;
    padding: 0 !important;
    .col-sm-12 {
      padding-top: 0;
      padding-left: 0 !important;
    }
  }
}
</style>
