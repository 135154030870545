<template>
  <v-main>
    <v-form :disabled="isComponent">
      <validation-observer ref="form">
        <v-row gutters="2" class="pt-0 pb-0" justify="end" v-if="!isComponent">
          <v-col cols="12" md="12" class="text-right">
            <Buttons
              :textCancel="$t('GENERAL.CANCEL')"
              v-on:cancel="cancel"
              :textSave="$t('GENERAL.SAVE')"
              v-on:save="saveData"
              :btnDuplicate="true"
              :textDuplicate="$t('GENERAL.DUPLICATE')"
              v-on:duplicate="duplicateData"
              :btnDelete="true"
              :textDelete="$t('GENERAL.DELETE')"
              v-on:delete="deleteRecord"
            />
          </v-col>
        </v-row>
        <v-row gutters="2" class="pt-0 pb-0">
          <v-col cols="12" md="8" class="pt-0 pb-0">
            <PackagesForm
              ref="packagesForm"
              :form-data.sync="formData"
              :package="packageData"
              :isOnlyShow="isComponent"
            ></PackagesForm>
          </v-col>
          <!-- Additional Info -->
          <v-col cols="12" md="4" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-title>{{ $t("GENERAL.VISIBILITY") }}</v-card-title>
              <v-card-text>
                <Visibility
                  :value="isReady"
                  :input.sync="isReady"
                  :isPublished="isPublish"
                ></Visibility>
              </v-card-text>
            </v-card>
            <v-card class="mb-2">
              <v-card-title>
                {{ $t("PACKAGES.FORM.MARKETS_COUNTRY_SELECTOR_TITLE") }}
              </v-card-title>
              <v-card-text>
                <MarketsCountriesSelector
                  :markets-countries-data="marketsCountriesData"
                  :input.sync="eligibleBy"
                  is-required
                  :isOnlyShow="isComponent"
                ></MarketsCountriesSelector>
              </v-card-text>
            </v-card>
            <Translation
              ref="translationComponent"
              :fields="translations"
              model="product"
              :isOnlyShow="isComponent"
            ></Translation>
          </v-col>
        </v-row>
        <v-dialog v-model="isShowDialogDelete" max-width="350">
          <v-card>
            <v-card-title class="headline">
              {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="red darken-1"
                text
                @click="isShowDialogDelete = false"
              >
                {{ $t("MESSAGE.DELETE.MODAL_NO") }}
              </v-btn>

              <v-btn color="primary darken-1" text @click="deletePackage()">
                {{ $t("MESSAGE.DELETE.MODAL_YES") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </validation-observer>
    </v-form>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_PRODUCT_PACKAGE,
  STORE_PRODUCT_PACKAGE,
} from "@/core/services/store/packages/product_package.module";

import Buttons from "@/view/content/components/Buttons";
import Visibility from "@/view/content/components/forms/Visibility";
import PackagesForm from "@/view/pages/packages/components/Form";
import MarketsCountriesSelector from "@/view/content/components/MarketsCountriesSelector";
import Translation from "@/view/content/components/translation/Index";

const PACKAGE_DURATION_VARIABLE = 1;
const PACKAGE_DURATION_FIXED = 2;

export default {
  name: "packages-edit",
  components: {
    Buttons,
    ValidationObserver,
    Visibility,
    PackagesForm,
    MarketsCountriesSelector,
    Translation,
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
    propProductId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isShowDialogDelete: false,
      deleteId: null,
      formData: {},
      packageData: {},
      eligibleBy: {},
      marketsCountriesData: {},
      isReady: null,
      isPublish: false,
      translations: [
        {
          title: this.$i18n.t("GENERAL.NAME"),
          type: "name",
        },
        {
          title: this.$i18n.t("GENERAL.DESCRIPTION"),
          type: "description",
        },
      ],
    };
  },
  created() {
    if (!this.isComponent) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("PACKAGES.TITLE"), route: "/packages" },
        { title: this.$t("PACKAGES.EDIT") },
      ]);
    }
  },
  async mounted() {
    let productId;
    if (this.isComponent) {
      productId = this.propProductId;
    } else {
      productId = this.$route.params.id;
    }
    await this.$store.dispatch(GET_PRODUCT_PACKAGE, productId);
    this.packageData = this.formatData();
  },
  computed: {
    ...mapGetters(["productPackage", "message", "campus"]),
  },
  methods: {
    ...mapActions(["updateProductPackage", "deleteProductPackage"]),
    async saveData() {
      if (await this.validateData()) {
        const response = await this.updateProductPackage({
          id: this.productPackage.productId,
          ...this.formatDataForDB(this.formData),
        });
        if (response) {
          this.$store.commit("setIsVisibleLoader", true);
          await this.$refs.translationComponent.getTranslations(
            response.productId,
            [response.product.name, response.product.description]
          );
          this.$store.commit("setIsVisibleLoader", false);
          this.packageData = this.formatData();
          this.$bvToast.toast(this.$t("PACKAGES.MESSAGE.SUCCESS_UPDATE"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast(
          this.$t("GENERAL.PLEASE_VERIFY_FIELDS").toString(),
          {
            title: "Error",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async duplicateData() {
      if (await this.validateData()) {
        const response = await this.$store.dispatch(
          STORE_PRODUCT_PACKAGE,
          this.formatDataForDB({
            ...this.formData,
            name: `${this.formData.name} Copy`,
          })
        );
        if (response) {
          this.$bvToast.toast(this.$t("PACKAGES.MESSAGE.SUCCESS_STORAGE"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
          await this.$router.push({
            name: "packages-edit",
            params: { id: response.productId },
          });
        }
      }
    },
    async deletePackage() {
      this.isShowDialogDelete = false;
      const response = await this.deleteProductPackage(this.deleteId);
      if (response) {
        await this.$bvToast.toast(this.$t("GENERAL.DELETED_SUCCESS"), {
          title: "Success",
          variant: "success",
          solid: true,
        });
        await this.$router.replace({
          name: "packages",
        });
      }
    },
    async validateData() {
      const globalValidation = await this.$refs.form.validate();
      const validationPackageForm = await this.$refs.packagesForm.validateForm();
      const validate = globalValidation && validationPackageForm;
      const hasOptions = this.formData.hasOwnProperty("packageOptions")
        ? this.formData.packageOptions
        : [];
      return validate && hasOptions.length;
    },
    async fetchData() {
      await this.$store.dispatch(GET_PRODUCT_PACKAGE, this.$route.params.id);
      this.packageData = this.formatData();
    },
    async cancel() {
      await this.$router.push({
        name: "packages",
      });
    },
    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format("Y-M-D");
    },
    formatData() {
      const {
        packageDurationType,
        product: {
          reqMinDurationTypeId,
          reqMinDurationAmount,
          reqMaxDurationAmount,
          productId,
          name,
          description,
          availabilityStart,
          availabilityEnd,
          isEligibleAllCountries,
          eligibleCountries,
          eligibleMarkets,
          isReady,
          isPublish,
        },
        product_editions,
        product_package_options,
        product_start_date_template: startDateTemplate,
        productStartDateTemplateId,
      } = this.productPackage;
      if (isEligibleAllCountries) {
        this.marketsCountriesData = {
          isEligibleAllCountries,
        };
      }
      if (eligibleCountries) {
        this.marketsCountriesData = {
          eligibleCountries,
        };
      }
      if (eligibleMarkets) {
        this.marketsCountriesData = {
          eligibleMarkets,
        };
      }
      this.isReady = isReady;
      this.isPublish = isPublish;
      const packageOptions = product_package_options?.map(option => {
        let durationTypeId = null;
        let durationAmount = null;
        option.product_package_option_items
          .filter(item => item.code === "ROOM")
          .forEach(value => {
            if (value.durationTypeId) {
              durationTypeId = value.durationTypeId;
            }
            if (value.durationAmount) {
              durationAmount = value.durationAmount;
            }
          });
        return {
          name: option.name,
          productPricingTemplateId: option.productPricingTemplateId,
          packageOptionItems: {
            coursesSelected: option.product_package_option_items
              .filter(item => item.code === "COURSE")
              .map(value => ({
                id: value.productId,
                name: value.name,
                durationAmount: value.durationAmount,
                durationTypeId: value.durationTypeId,
                quantity: value.quantity,
              })),
            roomsSelected: option.product_package_option_items
              .filter(item => item.code === "ROOM")
              .map(value => ({
                id: value.productId,
                name: value.name,
                durationAmount: value.durationAmount,
                durationTypeId: value.durationTypeId,
                quantity: value.quantity,
              })),
            servicesSelected: option.product_package_option_items
              .filter(item => item.code === "SERVICE")
              .map(value => ({
                productId: value.productId,
                name: value.name,
                durationAmount: value.durationAmount,
                durationTypeId: value.durationTypeId,
                quantity: value.quantity,
              })),
            feesSelected: option.product_package_option_fee_items?.map(fee => ({
              id: fee.feeId,
              name: fee.name,
            })),
            durationTypeId,
            durationAmount,
          },
        };
      });

      this.$refs.translationComponent.getTranslations(productId, [
        name,
        description,
      ]);

      return {
        name,
        description,
        packageDurationType,
        startDateTemplate,
        startDateType: productStartDateTemplateId ? 1 : 0,
        productStartDateTemplateId,
        weekly: {
          availabilityStart: this.formatDate(availabilityStart),
          availabilityEnd: this.formatDate(availabilityEnd),
          reqMinDurationAmount,
          reqMaxDurationAmount,
          reqMinDurationTypeId,
        },
        ranges: product_editions?.map(range => ({
          from: range.startDate,
          to: range.endDate,
          amount: range.amount,
        })),
        packageOptions,
      };
    },
    formatDataForDB(data) {
      const productTypeId = 6;
      const { campusId } = this.campus;
      const {
        name,
        description,
        packageDurationType,
        packageOptions,
        productStartDateTemplateId,
        startDateTemplate,
        startDateType,
      } = data;
      const {
        availabilityStart,
        availabilityEnd,
        reqMinDurationTypeId,
        reqMinDurationAmount,
        reqMaxDurationAmount,
      } = data.weekly;
      const validateDurationType =
        packageDurationType === PACKAGE_DURATION_VARIABLE;
      const productEditions = data.ranges?.map(range => ({
        start: range.from,
        end: range.to,
        amount: range.amount,
      }));
      const productPackageOption = packageOptions?.map(option => {
        const courses = option.packageOptionItems.coursesSelected?.map(
          course => ({
            productId: course.id,
            quantity: null,
            durationTypeId: null,
            durationAmount: null,
          })
        );
        const rooms = option.packageOptionItems.roomsSelected?.map(room => ({
          productId: room.id,
          quantity: null,
          durationTypeId: option.packageOptionItems.durationTypeId
            ? option.packageOptionItems.durationTypeId
            : null,
          durationAmount: option.packageOptionItems.durationAmount
            ? option.packageOptionItems.durationAmount
            : null,
        }));
        const services = option.packageOptionItems.servicesSelected?.map(
          service => ({
            ...service,
            productId: service.productId,
            quantity: service.quantity,
            durationTypeId: null,
            durationAmount: null,
          })
        );
        const productPackageOptionFeeItems = option.packageOptionItems.feesSelected?.map(
          fee => ({
            feeId: fee.id,
          })
        );
        const productPackageOptionItems = [...courses, ...rooms, ...services];
        return {
          name: option.name,
          productPricingTemplateId:
            packageDurationType === PACKAGE_DURATION_FIXED
              ? null
              : option.productPricingTemplateId,
          productPackageOptionItems,
          productPackageOptionFeeItems,
        };
      });
      const product = {
        isEligibleAllCountries: null,
        eligibleCountries: null,
        eligibleMarkets: null,
        ...this.eligibleBy,
        isReady: this.isReady,
        name,
        description,
        campusId,
        productTypeId,
        availabilityStart: validateDurationType ? availabilityStart : null,
        availabilityEnd: validateDurationType ? availabilityEnd : null,
      };

      return {
        reqMinDurationAmount: validateDurationType
          ? reqMinDurationAmount
          : null,
        reqMaxDurationAmount: validateDurationType
          ? reqMaxDurationAmount
          : null,
        packageDurationType,
        reqMinDurationTypeId: validateDurationType
          ? reqMinDurationTypeId
          : null,
        product,
        productEditions,
        productPackageOption,
        startDateType,
        startDateTemplate,
        productStartDateTemplateId,
      };
    },
    deleteRecord() {
      this.isShowDialogDelete = true;
      this.deleteId = this.$route.params.id;
    },
  },
  watch: {
    $route: "fetchData",
  },
};
</script>
