<template>
  <div class="PackageOptionTable">
    <v-toolbar flat>
      <v-toolbar-title>
        <span class="PackageOptionTable-title"> </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          x-small
          depressed
          class="mr-2"
          @click="editRow"
          v-if="!isOnlyShow"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          x-small
          depressed
          class="btn btn-icon btn-light btn-hover-danger"
          @click="isShowDialogDelete = true"
          v-if="!isOnlyShow"
        >
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <v-icon color="red" small>
              mdi-delete
            </v-icon>
          </span>
        </v-btn>
        <v-dialog v-model="isShowDialogDelete" max-width="350">
          <v-card>
            <v-card-title class="headline">
              {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="red darken-1"
                text
                @click="isShowDialogDelete = false"
              >
                {{ $t("MESSAGE.DELETE.MODAL_NO") }}
              </v-btn>

              <v-btn color="primary darken-1" text @click="deleteRow()">
                {{ $t("MESSAGE.DELETE.MODAL_YES") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-toolbar>
    <v-simple-table dense fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Type
            </th>
            <th class="text-left">
              Quantity
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in formatData(packageOption.packageOptionItems)"
            :key="item.name"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.quantity }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { map, filter } from "lodash";

import durationTypesFormat from "@/core/services/utils/durationTypesFormat";

export default {
  name: "PackageOptionsTable",
  props: {
    packageOption: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    editOption: {
      type: Function,
      required: true,
    },
    deleteOption: {
      type: Function,
      required: true,
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDialogDelete: false,
    };
  },
  computed: {
    ...mapGetters(["addons", "DurationTypes"]),
  },
  methods: {
    formatData(items) {
      const servicesFormatted = map(items.servicesSelected, service => {
        const name = map(
          filter(this.addons, addon => addon.productId === service.productId),
          "name"
        )[0];
        return {
          id: service.productId,
          name,
          type: "Service",
          quantity: service.quantity,
        };
      });
      const coursesFormatted = map(items.coursesSelected, course => ({
        ...course,
        quantity: "-",
        type: "Course",
      }));
      const feesFormatted = map(items.feesSelected, fee => ({
        ...fee,
        type: "Fee",
        quantity: 1,
      }));
      const roomsFormatted = map(items.roomsSelected, room => ({
        ...room,
        type: "Room",
        quantity: items.durationAmount
          ? `${this.durationLabel(items.durationTypeId, items.durationAmount)}`
          : "Same as course duration",
      }));

      return [
        ...coursesFormatted,
        ...servicesFormatted,
        ...roomsFormatted,
        ...feesFormatted,
      ];
    },
    durationLabel(durationId, amount) {
      const durationTypesFormatted = durationTypesFormat(this.DurationTypes, [
        "WEEK",
        "MONTH",
        "YEAR",
      ]);
      const codeName = map(
        filter(
          durationTypesFormatted,
          duration => duration.durationTypeId === durationId
        ),
        "codeName"
      )[0];
      return Number(amount) === 1
        ? `${amount} ${codeName?.slice(0, -1)}`
        : `${amount} ${codeName}`;
    },
    deleteRow() {
      this.deleteOption(this.index);
      this.isShowDialogDelete = false;
    },
    editRow() {
      this.editOption(this.index);
    },
  },
};
</script>

<style lang="scss">
.PackageOptionTable {
  border: 1px solid #eeeeee;
  &-title {
    font-weight: bold;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper {
    background: #fcfcfc;
    max-height: 300px;
  }
}
</style>
