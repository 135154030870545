<template>
  <validation-observer ref="servicesObserver">
    <v-row
      v-for="(service, index) in servicesSelected"
      :key="index"
      align="baseline"
      class="mt-0 mb-0 pt-0 pb-0"
    >
      <v-col cols="12" sm="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('PACKAGES.FORM.MODAL.SERVICES_CONTROL_SERVICE_LIST')"
          rules="required"
        >
          <v-autocomplete
            v-model="servicesSelected[index].productId"
            :items="servicesData"
            :label="$t('PACKAGES.FORM.MODAL.SERVICES_CONTROL_SERVICE_LIST')"
            item-text="name"
            item-value="productId"
            dense
            outlined
            :error-messages="errors"
            hide-selected
          />
        </validation-provider>
      </v-col>
      <v-col md="2">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('PACKAGES.FORM.MODAL.SERVICES_CONTROL_QUANTITY')"
          :rules="{ required: true, integer: true, min_value: 1 }"
        >
          <v-text-field
            type="number"
            v-model="servicesSelected[index].quantity"
            dense
            outlined
            :error-messages="errors"
            :label="$t('PACKAGES.FORM.MODAL.SERVICES_CONTROL_QUANTITY')"
          >
          </v-text-field>
        </validation-provider>
      </v-col>
      <v-col sm="1">
        <a
          href="#"
          class="btn btn-icon btn-light btn-hover-danger"
          @click="deleteService(index, $event)"
        >
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <v-icon color="red">
              mdi-delete
            </v-icon>
          </span>
        </a>
      </v-col>
    </v-row>
    <v-btn depressed color="primary" @click="addService" class="mt-0 mb-2">
      {{ $t("PACKAGES.FORM.MODAL.SERVICES_CONTROL_ADD") }}
    </v-btn>
  </validation-observer>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

export default {
  name: "ServicesControl",
  props: {
    servicesData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    servicesSelectedData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      servicesSelected: [],
    };
  },
  mounted() {
    if (this.servicesSelectedData.length) {
      this.servicesSelected = this.servicesSelectedData;
    }
  },
  methods: {
    async addService() {
      const validate = await this.$refs.servicesObserver.validate();
      if (validate) {
        this.servicesSelected.push({
          productId: null,
          quantity: null,
        });
      }
    },
    deleteService(index, event) {
      event.preventDefault();
      this.servicesSelected.splice(index, 1);
    },
  },
  watch: {
    servicesSelected: {
      handler: function() {
        this.$emit("update:services", this.servicesSelected);
      },
      deep: true,
    },
  },
};
</script>
