<template>
  <v-row class="pb-0 pt-0 mb-0 mt-0">
    <v-col cols="12" md="4">
      <validation-provider
        vid="minDuration"
        v-slot="{ errors }"
        :name="$t('PACKAGES.FORM.WEEKLY_CONTROL_MIN_DURATION_FIELD')"
        rules="required|integer|min_value:1"
      >
        <v-text-field
          hide-details="auto"
          class="required"
          type="number"
          v-model="weekly.reqMinDurationAmount"
          dense
          outlined
          :error-messages="errors"
          :label="$t('PACKAGES.FORM.WEEKLY_CONTROL_MIN_DURATION_FIELD')"
          required
        >
        </v-text-field>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('PACKAGES.FORM.WEEKLY_CONTROL_MAX_DURATION_FIELD')"
        :rules="{
          required: false,
          integer: true,
          min_value: 1,
          maxValue: { minDurationValue: '@minDuration' },
        }"
      >
        <v-text-field
          hide-details="auto"
          type="number"
          v-model="weekly.reqMaxDurationAmount"
          dense
          outlined
          :error-messages="errors"
          :label="$t('PACKAGES.FORM.WEEKLY_CONTROL_MAX_DURATION_FIELD')"
        >
        </v-text-field>
      </validation-provider>
    </v-col>
    <v-col cols="12" md="4">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('PACKAGES.FORM.WEEKLY_CONTROL_DURATION_TYPE_FIELD')"
        rules="required"
      >
        <v-select
          class="required"
          v-model="weekly.reqMinDurationTypeId"
          :items="getDurationTypes"
          :label="$t('PACKAGES.FORM.WEEKLY_CONTROL_DURATION_TYPE_FIELD')"
          item-text="codeName"
          item-value="durationTypeId"
          dense
          outlined
          required
          :error-messages="errors"
          hide-details="auto"
        />
      </validation-provider>
    </v-col>
    <v-col cols="12" class="mt-2">
      <v-row class="mb-2">
        <v-col cols="12">
          <h5>
            <b>
              {{
                $t("PACKAGES.FORM.PACKAGE_DURATION_START_DATE_TEMPLATE_TITLE")
              }}
            </b>
          </h5>
        </v-col>
        <v-col class="m-0 pt-0">
          <v-radio-group v-model="startDateType" row hide-details="auto">
            <v-radio
              :label="$t('PACKAGES.FORM.WEEKLY_CONTROL_SCHOOL_CALENDAR')"
              :value="0"
            ></v-radio>
            <v-radio
              :label="$t('PACKAGES.FORM.WEEKLY_CONTROL_CUSTOM_CALENDAR')"
              :value="1"
            ></v-radio>
          </v-radio-group>
          <v-expand-transition>
            <v-row
              class="mb-0"
              v-if="startDateType === startDateTypes('custom')"
            >
              <v-col cols="12">
                <StartDateTemplate
                  ref="startDateTemplate"
                  :startDateTemplate="startDateTemplate"
                  v-on:save_start_date_template="getDataStartDate($event)"
                ></StartDateTemplate>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5>
            <b>
              {{ $t("PACKAGES.FORM.WEEKLY_CONTROL_DATES_TITLE") }}
            </b>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              {{ $t("PACKAGES.FORM.WEEKLY_CONTROL_DATES_HELP") }}
            </v-tooltip>
          </h5>
        </v-col>
        <v-col>
          <DatePicker
            :date="weekly.availabilityStart"
            :is-required="false"
            :is-clearable="true"
            :name="$t('PACKAGES.FORM.WEEKLY_CONTROL_FROM_DATE')"
            :input.sync="weekly.availabilityStart"
            :change="clearAvailabilityEnd"
          />
        </v-col>
        <v-col>
          <DatePicker
            :date="weekly.availabilityEnd"
            :is-required="false"
            :is-clearable="true"
            :name="$t('PACKAGES.FORM.WEEKLY_CONTROL_TO_DATE')"
            :input.sync="weekly.availabilityEnd"
            :date-min="weekly.availabilityStart"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";

import durationTypesFormat, {
  DEFAULT_DURATION_TYPE,
} from "@/core/services/utils/durationTypesFormat";
import DatePicker from "@/view/content/components/DatePicker";
import StartDateTemplate from "@/view/content/components/forms/StartDateTemplate";

extend("maxValue", {
  params: ["minDurationValue"],
  validate: (value, { minDurationValue }) => {
    return Number(value) > Number(minDurationValue);
  },
  message: "It must be greater than {minDurationValue}",
});

export default {
  name: "WeeklyControl",
  props: [
    "weeklyData",
    "startDateTypeData",
    "startDateTemplateData",
    "isOnlyShow",
  ],
  components: {
    ValidationProvider,
    DatePicker,
    StartDateTemplate,
  },
  data() {
    return {
      from: false,
      to: false,
      startDateType: 0,
      startDateTemplate: {},
      weekly: {
        availabilityStart: null,
        availabilityEnd: null,
        reqMinDurationAmount: null,
        reqMaxDurationAmount: null,
        reqMinDurationTypeId: DEFAULT_DURATION_TYPE,
      },
    };
  },
  computed: {
    ...mapGetters(["DurationTypes"]),
    getDurationTypes() {
      const codeNames = ["WEEK", "MONTH"];
      return durationTypesFormat(this.DurationTypes, codeNames);
    },
  },
  mounted() {
    if (this.weeklyData) {
      this.weekly = {
        ...this.weeklyData,
        reqMinDurationTypeId:
          this.weeklyData.reqMinDurationTypeId || DEFAULT_DURATION_TYPE,
      };
    }
    if (this.startDateTypeData) {
      this.startDateType = this.startDateTypeData;
    }
    if (this.startDateTemplateData) {
      this.startDateTemplate = this.startDateTemplateData;
    }
  },
  methods: {
    clearAvailabilityEnd(date) {
      if (this.weekly.availabilityEnd) {
        this.weekly = {
          ...this.weekly,
          availabilityStart: date,
          availabilityEnd: null,
        };
      }
    },
    startDateTypes(type) {
      const types = {
        schoolCalendar: 0,
        custom: 1,
      };
      return types[type];
    },
    getDataStartDate(startDateTemplate) {
      this.startDateTemplate = startDateTemplate;
    },
  },
  watch: {
    weekly: {
      handler: function() {
        this.$emit("update:weekly", this.weekly);
      },
      deep: true,
    },
    startDateType: {
      handler: function() {
        this.$emit("update:startDateType", this.startDateType);
      },
      deep: true,
    },
    startDateTemplate: {
      handler: function() {
        this.$emit("update:startDateTemplate", this.startDateTemplate);
      },
      deep: true,
    },
  },
};
</script>
